.zones-col-1 {
  margin: 15px 0px 0px 270px;
}

.zones-col-2 {
  margin: -123px 0px 0px 750px;
  position: fixed;
}

.header-table {
  padding: 0px;
  width: 320px;
  background: #1d83f7;
  padding: 0px 0px 0px 0px !important;
}
.button-add-area {
  border-radius: 5px;
  width: 150px;
  margin: 26px 0px 0px 80px;
  font-weight: bold;
}

.search-zone-input {
  border-radius: 7px;
  width: 225px;
  margin: 15px 10px 15px 30px;
}

.search-icon {
  color: #1d83f7;
}

.ant-modal-header {
  padding: 16px 24px;
  border: none;
  /* color: rgba(0, 0, 0, 0.85); */
  /* background: #fff; */
  /* border-bottom: 1px solid #f0f0f0; */
  /* border-radius: 2px 2px 0 0; */
}

.ant-card-header {
  padding: 16px 24px;
  border: none;
  /* color: rgba(0, 0, 0, 0.85); */
  /* background: #fff; */
  /* border-bottom: 1px solid #f0f0f0; */
  /* border-radius: 2px 2px 0 0; */
}

.input-zone-area {
  border-radius: 7px;
  width: 280px;
  margin: 0px 0px 0px 30px;
}

.button-cancel-zone {
  border-radius: 5px;
  width: 130px;
  margin: 26px 20px 0px 30px;
  font-weight: bold;
  background-color: rgb(231, 231, 231);
}
.button-create-zone {
  border-radius: 5px;
  width: 130px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
}

.card-display-zone {
  width: 400px;
}
.slider-zone {
  width: 200px;
}

.ant-card-head {
  padding: 0 24px;
  font-weight: 500;
  font-size: 16px;
  border: none;
}

/* icon color here */
.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
  font-size: 15px;
}

.icon-wrapper .anticon:last-child {
  right: 0;
  font-size: 20px;
}

.menu-zone-area {
  margin: -15px 0px 0px 260px;
  position: unset;
}

.button-add-zone {
  border-radius: 5px;
  width: 150px;
  margin: 26px 0px 0px 140px;
  font-weight: bold;
  text-decoration: underline !important;
  font-size: 20px;
}

.zone-col-2 {
  margin: 0px 600px 0px 180px;
  width: 300px;
}
.button-schedule-edit {
  margin: -20px 20px 0px 270px;
}
.button-scene-edit {
  margin: -20px 20px 0px 292px;
}

.zone-title {
  color: #1d83f7;
  font-weight: bold;
}
