.button-dropdown-device {
  width: 370px;
  height: 55px;
  margin: 10px 0px 0px 40px;
}

.title-dropdown-device {
  font-size: 30px;
  font-weight: bold;
  margin: 3px 130px 0px 0px;
}

.modal-form-device {
  height: 450px;
}

.button-device-close {
  border-radius: 5px;
  width: 130px;
  margin: 350px 20px 0px 80px;
  font-weight: bold;
  background-color: rgb(231, 231, 231);
}


.input-search-device-area {
  border-radius: 7px;
  width: 370px;
  height: 50px;
  margin: 40px 15px 15px 40px;
}

.title-control-zone {
  font-weight: bold;
  margin: 20px 0px -20px 190px;
}


.selected-zone-control{
    width: 880px;
    height: 80px;
    padding: 0px 0px 0px 0px;

}

.demo-loadmore-list{
    width: 880px;
    border-radius: 15px;
    position: unset;

}

.button-add-controlzone{
    margin: 20px 20px 0px 620px;
    border-radius: 7px;
    width: 120px;
    height: 30px;
    border: none;
    font-weight: bold;
}

.button-save-controlzone{
    
    border-radius: 7px;
    background-color: lightgreen;
    width: 120px;
    height: 30px;
    border: none;
    font-weight: bold;
}