/* Logo */
.logo {
  line-height: 100px;
}
.pageName {
  margin-left: 20px;
  font-weight: bold;
}
.site-layout {
  background-color: #e6eaee !important;
}
.user-dropdown {
  position: absolute;
  font-weight: bold;
  left: 88%;
  top: 0;
}
/* F7F9FC */
.logo-text {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin: 0 0px 0px 22px;
  color: white;
}
.ant-layout-sider-children {
  position: fixed;
}

.border-line {
  width: 165px;
  border-bottom: 1px solid white;
  margin-top: -27px;
  margin-left: 15px;
}
.border-line-collapsed {
  width: 80px;
  border-bottom: 1px solid white;
  margin-top: -27px;
}

.logo-dashboard {
  margin: 26px 16px 58px 26px;
}
.logo-name {
  color: #ff4000;
  padding-top: 26px;
}

/* Header */
.site-layout .site-layout-background {
  background-color: #ffffff;
  overflow: hidden;
  top: 0;
  width: 120%;
  z-index: 1;
}

.header-text {
  text-align: right;
  font-size: small;
  color: white;
  margin: 0px;
  padding: 0px;
  height: 0px;
}

/* Content */
.content-layout {
  padding: 55px;
  min-height: 100vh;
}

.ant-menu-item-selected .span-menu {
  color: #f65151;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.badge-icon {
  margin: 0px 0px 0px 10px !important;
}

.ulter-padding {
  padding-left: 50px !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 111% !important;
}
