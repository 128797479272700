.modal-device-setting {
  background-color: rgb(51, 111, 190);
}
.input-device-id {
  width: 100px;
  border-radius: 5px;
}

.input-zone-name {
  width: 100px;
  border-radius: 5px;
}

.input-location {
  width: 145px;
  border-radius: 5px;
}

.input-timeout {
  width: 300px;
}

.input-sensitivity {
  width: 300px;
}

.input-powerup-state {
  border-radius: 5px;
}

.input-title {
  font-size: 11px;
  font-weight: bold;
}

.form-title {
  font-weight: bold;
  margin: 0px 0px -20px 155px;
}

.button-form-close {
  border-radius: 5px;
  width: 130px;
  margin: 26px 20px 0px 80px;
  font-weight: bold;
  background-color: rgb(231, 231, 231);
}
.button-form-save {
  border-radius: 5px;
  width: 130px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
}
