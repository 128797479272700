.topnav {
  background-color: #ffffff;
  /* overflow: hidden; */
  height: 150px;
  overflow-x: hidden;
}

.border-top {
  border-top: 1px solid rgb(236, 233, 233);
  margin-left: auto;
  margin-right: auto;
  width: 70em;
}

.header-title {
  font-size: 22px;
  font-weight: bold;
}

.slider {
  width: 350px;
}
